<template>
  <div
    v-if="message"
    :class="`ai-chat dark:bg-fr-gray-600 space-y-2 rounded-md p-3 ${
      message.role === 'user' ? 'bg-fr-blue/30' : 'border-fr-gray-200 border'
    }`"
  >
    <!-- Message -->
    <div v-if="messageContent.message">
      {{ messageContent.message }}
    </div>

    <!-- Product -->
    <div
      v-if="
        messageContent.product && typeof messageContent.product !== 'string'
      "
      class="flex flex-col gap-3"
    >
      <div class="grid w-full grid-cols-3 gap-3">
        <q-img
          v-for="(image, index) in messageContent.product.images"
          :key="index"
          :src="image"
          :ratio="1"
          class="flex-1 rounded-md"
        />
      </div>
      <p v-if="messageContent.product.name">
        NAME: {{ messageContent.product.name }}
      </p>
      <p v-if="messageContent.product.sku && !messageContent.product.price">
        SKU: {{ messageContent.product.sku }}
      </p>
      <p v-if="messageContent.product.brand">
        BRAND: {{ messageContent.product.brand }}
      </p>
      <p v-if="messageContent.product.description">
        DESCRIPTION: {{ messageContent.product.description }}
      </p>
      <barcode-component
        v-if="messageContent.product.barcode"
        :value="messageContent.product.barcode"
        :display-value="true"
        :font-size="14"
        :height="60"
        :width="2"
      />
      <p v-if="messageContent.product.price">
        {{ messageContent.product.price }}
        {{ messageContent.product.taxIncluded ? 'tax included' : '' }}
      </p>

      <fr-button
        v-if="messageContent.product.sku && !messageContent.product.price"
        class="product-price"
        :data-sku="messageContent.product.sku"
      >
        View prices
      </fr-button>
    </div>

    <!-- product -->
    <div
      v-if="
        messageContent.product && typeof messageContent.product === 'string'
      "
    >
      {{ messageContent.product }}
    </div>

    <!-- products -->
    <div
      v-if="
        messageContent.products &&
        typeof messageContent.products !== 'string' &&
        messageContent.products.length > 0
      "
      class="flex flex-col gap-3"
    >
      <div
        v-for="product in messageContent.products"
        :key="product.sku"
        class="bg-fr-gray-900/20 flex flex-col gap-3 rounded-md p-3"
      >
        <p v-if="product.name">NAME: {{ product.name }}</p>
        <p v-if="product.sku">SKU: {{ product.sku }}</p>
        <p v-if="product.brand">BRAND: {{ product.brand }}</p>
        <barcode-component
          v-if="product.barcode"
          :value="product.barcode"
          :display-value="true"
          :font-size="14"
          :height="60"
          :width="2"
        />

        <fr-button
          v-if="product.sku"
          class="product-sku"
          :data-sku="product.sku"
        >
          View product
        </fr-button>
      </div>
    </div>

    <!-- products -->
    <div
      v-if="
        messageContent.products && typeof messageContent.products === 'string'
      "
    >
      {{ messageContent.products }}
    </div>

    <!-- images -->
    <div
      v-if="
        messageContent.images &&
        typeof messageContent.images !== 'string' &&
        messageContent.images.length > 0
      "
    >
      <div class="flex w-full flex-row gap-3">
        <q-img
          v-for="(image, index) in messageContent.images"
          :key="index"
          :src="image"
          :ratio="1"
          class="flex-1 rounded-md"
        />
      </div>
    </div>

    <!-- People -->
    <div
      v-if="
        messageContent.people &&
        typeof messageContent.people !== 'string' &&
        messageContent.people.length > 0
      "
      class="flex flex-col gap-3"
    >
      <div
        v-for="person in messageContent.people"
        :key="person.email"
        class="bg-fr-gray-900/20 flex flex-col gap-3 rounded-md p-3"
      >
        <div class="flex flex-row gap-3">
          <q-img
            v-if="person.image"
            :src="person.image"
            :ratio="1"
            class="flex-1 rounded-md"
          />
          <div class="flex flex-col gap-3">
            <p v-if="person.name">NAME: {{ person.name }}</p>
            <p v-if="person.email">EMAIL: {{ person.email }}</p>
            <p v-if="person.role">ROLE: {{ person.role }}</p>
            <p v-if="person.department">DEPARTMENT: {{ person.department }}</p>
            <p v-if="person.location">LOCATION: {{ person.location }}</p>
          </div>
        </div>
        <fr-button
          v-if="person.name"
          class="person-name"
          :data-person="person.name"
        >
          Tell me about this person
        </fr-button>
      </div>
    </div>

    <q-separator />

    <div class="flex flex-row gap-3">
      <q-icon
        :name="`fal ${message.role !== 'assistant' ? 'fa-user' : 'fa-robot'}`"
        color="white"
        :class="`h-4 w-4 flex-shrink-0 items-center justify-center rounded-full ${
          message.role !== 'assistant'
            ? 'bg-fr-secondary-500'
            : 'bg-fr-primary-500'
        } p-2`"
      />
      <!-- Copy text -->
      <fr-button
        v-if="message.role !== 'user'"
        icon="fas fa-file"
        @click="() => message && fr_copyToClipboard(message.content)"
      >
        Copy
      </fr-button>
      <!-- Tts -->
      <fr-button
        v-if="message.role !== 'user'"
        :loading="isLoadingTextToSpeech"
        @click="() => getTextToSpeech()"
      >
        <div class="relative flex flex-row items-center gap-1">
          <span
            v-if="isPlaying"
            class="absolute right-0 top-0 h-full w-full animate-ping"
          />
          <q-icon :name="isPlaying ? 'fas fa-stop' : 'fas fa-volume-up'" />
          <span>
            {{ isPlaying ? 'Stop' : 'Talk to me' }}
          </span>
        </div>
      </fr-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AIMessageType } from '~/features/chatbot/api/types'
import useAuthStore from '~/features/auth/store'
import { useChatBotStore } from '~/features/chatbot/store'
import factories from '~/factories'
const authStore = useAuthStore()
const chatbotStore = useChatBotStore()

const props = withDefaults(
  defineProps<{
    message: AIMessageType | null
  }>(),
  {
    message: null
  }
)

const isPlaying = ref(false)

const audio = computed(() => new Audio())

const createAudio = computed(() => chatbotStore.createAudio)

const watchForNewMessage = computed(() => props.message?.newMessage)

const alreadyPlayed = computed({
  get: () => chatbotStore.alreadyPlayed,
  set: (value) => {
    chatbotStore.alreadyPlayed = value
  }
})

function isJsonString(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

type messageContentType = {
  message?: string
  images?: string[]
  product?: {
    name: string
    sku: string
    images: string[]
    description: string
    specifications: string
    barcode: string
    brand: string
    price: string
    taxIncluded: boolean
    currency: string
  }
  products?: {
    name: string
    sku: string
    barcode: string
    brand: string
  }[]
  people: {
    name: string
    email: string
    role: string
    department: string
    location: string
    image: string
  }[]
}

const messageContent = computed<messageContentType>(() => {
  if (isJsonString(props.message?.content ?? '{}')) {
    const content = JSON.parse(props.message?.content ?? '{}')
    return content
  } else {
    return {
      message: props.message?.content
    }
  }
})

const { mutateAsync: TTS, isPending: isLoadingTextToSpeech } =
  factories.AI.mutations.useGetTextToSpeech({
    options: {
      onSuccess: (res) => {
        console.log(res)

        audio.value.src = res
        audio.value.play()

        isPlaying.value = true

        alreadyPlayed.value = true
      }
    }
  })

const getTextToSpeech = () => {
  if (isPlaying.value) {
    audio.value.pause()
    isPlaying.value = false

    return
  }
  if (messageContent.value.message) {
    TTS({
      username: authStore.user?.name ?? 'Anonymous',
      message: messageContent.value.message
    })
  }
}

onMounted(() => {
  audio.value.addEventListener('ended', () => {
    isPlaying.value = false
  })
})

watch(
  [createAudio, watchForNewMessage],
  () => {
    if (
      !watchForNewMessage.value ||
      !createAudio.value ||
      props.message?.role === 'user'
    )
      return

    TTS({
      username: authStore.user?.name ?? 'Anonymous',
      message: messageContent.value?.message ?? ''
    })
  },
  {
    immediate: true
  }
)
</script>
~/features/chatbot/api/types~/features/chatbot/store
~/features/chatbot/api/types~/features/chatbot/store
