<template>
  <div
    class="relative flex flex-1 flex-col flex-nowrap justify-between space-y-1 text-fr-gray-900 dark:text-fr-gray-100"
  >
    <q-tabs
      v-model="state.target.value"
      class="bg-fr-gray-100 dark:bg-fr-gray-900"
      active-color="fr-secondary-500"
      indicator-color="fr-secondary-500"
      align="justify"
      dense
      no-caps
    >
      <q-tab
        v-for="tab in ['History', 'Chat', 'How to use']"
        :key="tab"
        :name="tab"
        :label="tab"
        class="text-fr-gray-900 dark:text-fr-gray-100"
      />
    </q-tabs>

    <q-tab-panels
      v-model="state.target.value"
      class="flex flex-1 flex-col flex-nowrap overflow-hidden"
    >
      <!-- History -->
      <q-tab-panel name="History">
        <div
          v-for="chat in chats"
          :key="chat.id + chat.name"
          class="flex flex-row flex-nowrap items-center justify-between gap-3 border-b border-fr-gray-200 p-3 dark:border-fr-gray-700"
        >
          <div class="flex flex-auto flex-row flex-nowrap gap-3">
            <div
              v-if="chat.messages && chat.messages.length > 0"
              class="flex w-full flex-col gap-3 text-sm text-fr-gray-900 dark:text-fr-gray-100"
            >
              {{ chat.name }}

              <span class="text-fr-gray-600 dark:text-fr-gray-300">
                {{ chat.date }}
              </span>
            </div>
            <div class="flex flex-1 flex-row gap-3">
              <fr-button
                icon="fas fa-external-link-alt"
                @click="
                  () => {
                    selectedChat = chat
                    state.target.value = 'Chat'
                  }
                "
              />
              <!-- Delete chat -->
              <fr-button
                icon="fas fa-trash"
                @click="
                  () => {
                    $q.dialog({
                      title: 'Delete chat',
                      message: 'Are you sure you want to delete this chat?',
                      cancel: true,
                      persistent: true
                    }).onOk(() => {
                      chats.splice(chats.indexOf(chat), 1)
                      selectedChat = chats[0]
                    })
                  }
                "
              />
            </div>
          </div>
        </div>
      </q-tab-panel>
      <!-- Chat -->
      <q-tab-panel name="Chat">
        <chat-messages
          :messages="selectedChat?.messages"
          :is-loading-messages="isLoadingChatbotMessages"
        />
      </q-tab-panel>
      <!-- Instructions -->
      <q-tab-panel name="How to use">
        <div class="flex flex-col gap-3">
          <h3 class="text-lg">How to use the chatbot</h3>

          <ul class="space-y-2">
            <li>
              <strong>Ask a question</strong> - Type a question in the chatbox
              and press enter
            </li>
            <li>
              <strong>Copy text</strong> - Click the copy button on the right
              side of the message
            </li>
            <li>
              <strong>Send Feedback</strong> - Click the feedback button on the
              bottom right of the chatbox and fill in the form to send feedback
            </li>
          </ul>

          <q-separator />

          <h3 class="text-lg">What can the chatbot do?</h3>

          <ul class="space-y-2">
            <li>
              <q-chip class="bg-fr-primary-500 text-fr-gray-0"
                >Product info</q-chip
              >
              - Ask the chatbot about a product and it will show you the product
              details.
            </li>
            <li>
              <q-chip class="bg-fr-primary-500 text-fr-gray-0"
                >Product price</q-chip
              >
              - Ask the chatbot about a product's price, country and it will
              show you the product price for that country.
            </li>
            <li>
              <q-chip class="bg-fr-primary-500 text-fr-gray-0"
                >Product stock</q-chip
              >
              - Ask the chatbot about a product's stock and it will show you the
              product availability. Optionally you can specify a country and or
              a warehouse as well.
            </li>
            <li>
              <q-chip class="bg-fr-primary-500 text-fr-gray-0"
                >Track goals</q-chip
              >
              - Ask the chatbot about your goals and it will show your goals.
            </li>
            <li>
              <q-chip class="bg-fr-primary-500 text-fr-gray-0"
                >Find people</q-chip
              >
              - Ask the chatbot about a person and it will show you the person's
              details.
            </li>
          </ul>
        </div>
      </q-tab-panel>
    </q-tab-panels>
    <footer
      class="mx-auto w-full max-w-4xl space-y-2 rounded-md bg-fr-gray-900/40 p-2 dark:border-gray-700 dark:bg-fr-gray-900"
    >
      <div class="flex items-center justify-between">
        <fr-button
          icon="fas fa-plus"
          :disabled="isLoadingChatbotMessages"
          @click="
            () => {
              newChat()

              state.target.value = 'Chat'
            }
          "
        >
          New chat
          <q-tooltip>
            <div>Start a new chat</div>
          </q-tooltip>
        </fr-button>
        <!-- Feedback button with form -->
        <div class="flex flex-row gap-2">
          <!-- Create Audio button -->
          <fr-button
            :disabled="isLoadingChatbotMessages"
            icon="fas fa-microphone"
            @click="
              () => (chatBotStore.createAudio = !chatBotStore.createAudio)
            "
          >
            <q-tooltip>
              <div>Create audio for new messages</div>
            </q-tooltip>
          </fr-button>

          <chat-voice-call />

          <fr-button
            icon="fas fa-info"
            :disabled="isLoadingChatbotMessages"
            @click="
              () => {
                state.feedbackForm.value = true
              }
            "
          >
            <q-tooltip>
              <div>Feedback</div>
            </q-tooltip>
          </fr-button>
        </div>

        <!-- Feedback -->
        <q-dialog v-model="state.feedbackForm.value">
          <q-card class="q-dialog-plugin">
            <q-card-section>
              <div class="text-xl">Feedback</div>
              <div class="text-sm text-fr-gray-500">
                Please let us know how we can improve our chatbot
              </div>
              <q-form
                @submit="
                  () => {
                    if (state.feedbackFormMessage.value === null) return
                    submitFeedback({
                      feedback: state.feedbackFormMessage.value
                    })
                  }
                "
              >
                <q-input
                  v-model="state.feedbackFormMessage.value"
                  label="Message"
                  type="textarea"
                  lazy-rules
                  :rules="[(val) => !!val || 'Message is required']"
                />
                <q-card-actions align="right">
                  <fr-button
                    :loading="isLoadingSubmitFeedback"
                    label="Cancel"
                    @click="state.feedbackForm.value = false"
                  >
                    Cancel
                  </fr-button>
                  <fr-button type="submit"> Submit </fr-button>
                </q-card-actions>
              </q-form>
            </q-card-section>
          </q-card>
        </q-dialog>

        <!-- End Feedback button -->
      </div>

      <!-- Input -->
      <div class="relative">
        <textarea
          v-model="state.message.value"
          autofocus
          class="block w-full rounded-md border-gray-200 p-3 pb-6 text-sm focus:border-blue-500 focus:ring-blue-500 dark:border-gray-700 dark:bg-fr-gray-800 dark:text-gray-400"
          placeholder="Ask me anything..."
          @keydown.enter.prevent="
            () => getRes({ message: state.message.value })
          "
        />

        <!-- Toolbar -->
        <div class="absolute bottom-px right-1 rounded-b-md p-2">
          <div class="flex items-center justify-between">
            <!-- Button Group -->
            <div class="flex items-center gap-x-1">
              <!-- Send Button -->
              <fr-button
                icon="fas fa-paper-plane"
                :disabled="!state.message.value || isLoadingChatbotMessages"
                :loading="isLoadingChatbotMessages"
                @click="getRes({ message: state.message.value })"
              >
              </fr-button>
              <!-- End Send Button -->
            </div>
            <!-- End Button Group -->
          </div>
        </div>
        <!-- End Toolbar -->
      </div>
      <!-- End Input -->
    </footer>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import useAuthStore from '~/features/auth/store'
import { useChatBotStore } from '~/features/chatbot/store'
import ChatMessages from './chat-messages.vue'
import ChatVoiceCall from './chat-voice-call.vue'
import factories from '~/factories'

const authStore = useAuthStore()
const chatBotStore = useChatBotStore()

const $q = useQuasar()

const state = toRefs(
  reactive<{
    message: string
    page: string
    subPage: string
    regenerating: boolean
    target: string
    messages: {
      content: string
      role: string
    }[]
    chat_history: []
    boxHeight: number
    feedbackForm: boolean
    feedbackFormMessage: string | null
  }>({
    message: '',
    page: '',
    subPage: '',
    regenerating: false,
    target: 'Chat',
    messages: [],
    chat_history: [],
    boxHeight: 0,
    feedbackForm: false,
    feedbackFormMessage: null
  })
)

const user = useUser()

// const alreadyPlayed = computed({
//   get: () => chatBotStore.alreadyPlayed,
//   set: (value) => {
//     chatBotStore.alreadyPlayed = value
//   }
// })

const chats = computed({
  get: () => authStore.chats,
  set: (val) => {
    authStore.chats = val
  }
})

const selectedChat = computed({
  get: () => authStore.selectedChat,
  set: (val) => {
    authStore.selectedChat = val
  }
})

const botPersonalityPreset = {
  bot: {
    name: 'chatbot Arnie',
    language: 'any language',
    tone: 'helpful, funny, positive'
  },
  user: {
    name: user.value?.name || user.value?.email?.split('@')[0] || '',
    email: user.value?.email || '',
    language: 'any language',
    Role: user.value?.jobTitle || 'Employee'
  },
  useFunctions: true,
  customData: `Company: Front Runner Outfitters. companyAndEmployeeID: ${user.value?.employeeID}
  current page: TITLE: ${authStore.currentPageMeta.title}, URL: ${authStore.currentPageMeta.path}, DESCRIPTION: ${authStore.currentPageMeta.description}`
}

const { mutateAsync: sendMessage, isPending: isLoadingChatbotMessages } =
  factories.AI.mutations.useSendMessage({
    onSuccess: (res) => {
      selectedChat.value.messages = res.messages.map((m, index) => ({
        ...m,
        newMessage: index === res.messages.length - 1
      }))
      setTimeout(() => {
        const scrollWindow = document.getElementById('chatWindow')
        scrollWindow?.scrollTo({
          left: 0,
          top: scrollWindow?.scrollHeight,
          behavior: 'smooth'
        })
      }, 200)
    },
    onError: () => {
      selectedChat.value.messages = [
        ...selectedChat.value.messages,
        {
          content: 'Sorry, I am not available right now',
          role: 'assistant'
        }
      ]
    }
  })

/**
 * Send feedback
 */

const { mutateAsync: submitFeedback, isPending: isLoadingSubmitFeedback } =
  factories.AI.mutations.useSendFeedback({
    options: {
      onSuccess: () => {
        state.feedbackForm.value = false
        state.feedbackFormMessage.value = null
      },
      onError: () => {
        state.feedbackForm.value = false
        state.feedbackFormMessage.value = null
      }
    }
  })

const getRes = async ({ message }: { message: string }) => {
  if (user.value?.id === null) return

  if (!message && selectedChat.value) return

  selectedChat.value?.messages.push({
    role: 'user',
    content: `${message}`,
    personality: botPersonalityPreset
  })

  state.message.value = ''

  setTimeout(() => {
    const scrollWindow = document.getElementById('chatWindow')
    scrollWindow?.scrollTo({
      left: 0,
      top: scrollWindow?.scrollHeight,
      behavior: 'smooth'
    })
  }, 200)

  setTimeout(async () => await sendMessage(selectedChat.value.messages), 200)

  selectedChat.value.name =
    selectedChat.value.messages.find((m) => m.role === 'user')?.content ||
    'New chat'
}

const newChat = async () => {
  if (!user.value?.id) return

  chats.value.push({
    id: chats.value.length + 1 + Math.random() * 1000,
    name: 'New chat',
    messages: [],
    // Format: as day, month, year
    date: dayjs().format('MMMM D YYYY, h:mm:ss a')
  })

  selectedChat.value = chats.value[chats.value.length - 1]

  selectedChat.value.messages.push({
    role: 'system',
    content: `Say welcome to the user. Their name is ${
      user.value?.name || user.value?.email.split('@')[0] || ''
    } and their email is ${user.value?.email || ''}`,
    personality: botPersonalityPreset
  })
  await sendMessage(selectedChat.value.messages)
}

watch(
  () => selectedChat.value.messages,
  (val) => {
    if (val.length > 0) {
      nextTick(() => {
        document.querySelectorAll('.product-sku').forEach((node) => {
          console.log(node)

          // @ts-expect-error node is a button already.
          node.onclick = () => {
            const sku = node.getAttribute('data-sku')
            const message = `I want to know more about ${sku}`
            getRes({ message })
          }
        })

        document.querySelectorAll('.product-price').forEach((node) => {
          // @ts-expect-error node is a button already.
          node.onclick = () => {
            const sku = node.getAttribute('data-sku')
            const message = `I want to know the price of ${sku}`
            getRes({ message })
          }
        })

        document.querySelectorAll('.person-name').forEach((node) => {
          // @ts-expect-error node is a button already.
          node.onclick = () => {
            const name = node.getAttribute('data-person')
            const message = `I want to know more about ${name}`
            getRes({ message })
          }
        })
      })
    }
  },
  { immediate: true }
)

watch(isLoadingChatbotMessages, () => {
  setTimeout(() => {
    const scrollWindow = document.getElementById('chatWindow')
    scrollWindow?.scrollTo({
      left: 0,
      top: scrollWindow?.scrollHeight,
      behavior: 'smooth'
    })
  }, 200)
})

onMounted(() => {
  if (
    (user.value?.id && selectedChat.value.messages.length === 0) ||
    (user.value?.id && chats.value.length === 0)
  ) {
    newChat()
  }
})

watch(
  user,
  (val) => {
    if (val && selectedChat.value.messages.length === 0) {
      newChat()
    }
  },
  {
    immediate: true
  }
)
</script>
